.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color:#fda94f!important ;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: #fda94f!important;
}
.MuiSvgIcon-root{
    color: #fda94f!important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #fda94f!important;
}
.makeStyles-prev-15{
    width: 50%;
}

.makeStyles-next-14{
    width: 50%;
}
.makeStyles-next-14 button{
    float: right;
}

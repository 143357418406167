.flip-card {
    background-color: transparent;
    width: 300px;
    height: 255px;
    border: 1px solid transparent;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 300px;
    height: 255px;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  .cardImage {
    width: 300px;
    height: 255px;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: transparent;
    color: white;
  }
  
  /* Style the back side */
  .flip-card-back {
    background-color: #fda94f;
    color: white;
    transform: rotateY(180deg);
  }
  
@font-face {
  font-family: "ubuntu";
  src: url('../fonts/Ubuntu/Ubuntu-Regular.ttf');
}

a,
p,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  font-family: "ubuntu";
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f6f6f6;
}

::-webkit-scrollbar-thumb {
  background: #fda94f;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fda94f;
}

.css-1diafny-MuiSlider-root {
  color: #fda94f !important;
}

.location {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin: 0px !important;
}

.btn-yellow-outline {
  background: #fff;
  color: #fda94f !important;
  border: 1px solid #fda94f;
}

.property-news-single-card {
  margin-bottom: 50px !important;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
  /* border: unset !Important; */
}

.rld-list-style li {
  margin-bottom: 0px !important;
}

.pointer {
  cursor: pointer !important;
}

.reg_background_image {
  background: url("../img/registration_background.png");
  background-size: cover;
  min-height: 100vh;
  height: auto;
}

.log_background_image {
  background: url("../img/registration_background.png");
  background-size: cover;
  min-height: 100vh;
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}


.ProfileImgDiv {
  position: relative;
  width: 50%;
  max-width: 300px;
  cursor: pointer;
}

.ProfileImage {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.css-35zqo5-MuiTabs-root {
  min-width: 110px
}

#vertical-tabpanel-1 {
  width: 80%;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fda94f !important;
}

.css-10d9dml-MuiTabs-indicator {
  background-color: #fda94f !important;
}

.css-wb2sm {
  background-color: #fff5ea !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #fda94f !important;
}

.btn-primary {
  background-color: #fda94f !important;
}

.text-primary {
  color: #fda94f !important;
  font-weight: bold;
  cursor: pointer;
}

.overlay {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.ProfileImgDiv:hover .overlay {
  opacity: 1;
}

.allAgencies-agencyTitle:hover {
  color: #fda94f;
  cursor: pointer;
}

.imagePickerCustom {
  height: 150px;
  width: 150px;
  border: 1px dashed;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePickerCustom:hover {
  cursor: pointer;
}

.react-tabs__tab-list {
  border-bottom: 4px solid #fda94f !important;
}

.title {
  font-weight: bold;
}

.thumb-img {
  height: 350px
}

.ImageUploader {
  height: 130px;
  width: 130px;
  background: #fda94f52;
  border: 4px dashed;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-clip: padding-box;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav > li{
  margin-right: 0px!important;
}

.activeBG{
  background-color: #28a745;
}
.deactiveBG{
  background-color: rgb(194, 4, 4);
}

.statusBadge{
  position: absolute;
  padding: 5px 20px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  z-index: 9;
  border-radius: 10px 0px 0px 0px;
}

.ImageUploader h5 {
  color: #fda94f;
  font-weight: bolder;
}

.ImageUploader .updateImageText {
  color: #fda94f;
  font-weight: bolder;
  margin: 0;
  background: #00000087;
  padding: 34px 0px;
  width: 100%;
}

.appendImageUploader {
  background: #666666;
  height: 25px;
  width: 100%;
  border-radius: 50px;
  position: absolute;
  right: -14px;
  top: 40%;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.cancelImage {
  cursor: pointer;
  text-align: center;
  height: 25px;
  width: 25px;
  position: absolute;
  background: #fda94f;
  border-radius: 50%;
  top: -12px;
  right: -6px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: 18px;
  color: #ffffff;
  z-index: 99;
}

.thumb-img img {
  height: 100%;
  object-fit: cover;
}

.MuiSlider-rail,
.MuiSlider-track {
  color: #fda94f
}

.v-hidden {
  visibility: hidden;
}

.MuiSlider-thumbSizeMedium::before {
  background: #fda94f !important;
}

.form-control {
  height: 48px !important;
}

.sub-menu li a {
  color: #000 !important;
}

#c-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000038;
  z-index: 2;
  cursor: pointer;
}

#c-overlay #text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

#landmark_searchbox {
  border: 1px solid #d4d4d4;
  position: absolute;
  width: 100%;
  height: 200px;
  background: white;
  z-index: 9;
  margin-top: 5px;
  border-radius: 5px;
  box-shadow: 0px 3px 4px #00000030;
}

.list-style-none {
  list-style: none;
}

#landmark_searchbox li {
  border-bottom: 1px solid #f6f6f6;
  margin-top: 10px;
  padding-left: 15px;
}

#landmark_searchbox li:hover {
  cursor: pointer;
  background: #fda94f21;
}

#landmark_searchbox ul {
  overflow-y: scroll;
  height: 200px;
}

#landmark_searchbox em {
  color: #fda94f;
  font-weight: bold;
}

.landmark_searchbox_overlay {
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: transparent;
  top: 0;
  z-index: 9;
}